import React, { useState, useEffect } from 'react';
import { Link } from 'preact-router/match';
import style from './style.css';
import useAuth from "../auth";
import {useAuthStore} from '../auth/store';
import getUuid from "uuid-by-string";

const Header = () => {
  const {loginWithGoogle, logoutHook} = useAuth();
  const {isAuthenticated} = useAuthStore();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
  localStorage.removeItem('hasConsented')

 	const hasConsented = localStorage.getItem('hasConsented-v1');
    if (hasConsented === null) {
      setIsModalOpen(true);
    }
  }, []);

  function handleConsent() {
	setIsModalOpen(false);
	localStorage.setItem('hasConsented-v1', true);
	 // store additional device data in local storage
	 localStorage.setItem('deviceData', JSON.stringify({
		id:getUuid(`${Date.now()}${navigator.platform}${navigator.userAgent.slice(0,10)}`.replace(/[/]/g, '')),
		browser: navigator.userAgent,
    platform: navigator.platform,
    device:`${Date.now()}${navigator.platform}${navigator.userAgent.slice(0,10)}`.replace(/[/]/g, '')
	  }));
	  window.location.reload()
  }

  function handleDecline() {
    setIsModalOpen(false);
    localStorage.setItem('hasConsented-v1', false);
  }

  function handleLogin() {
    loginWithGoogle();
  }

  function handleLogout() {
    logoutHook();
  }
  return (
    <>
      <header class={style.header}>
        <a onClick={() => { isAuthenticated ? handleLogout():handleLogin() }} class={style.logo}>
<img src="../../assets/logo.png" alt="Preact Logo" height="55" width="55" />
<h1>la Dogeria</h1>
</a>
<nav>
<Link activeClassName={style.active} href="/menu">
products
</Link>
</nav>
</header>
{isModalOpen && (
<div class={style.modalContainer}>
<p>We use cookies to improve your experience on our site.</p>
<button onClick={handleConsent}>Accept</button>
<button onClick={handleDecline}>Decline</button>
</div>
)}
</>
);
};

export default Header;