import { h } from 'preact';
import { Router, route } from 'preact-router';

import Header from './header';
import {useAuthStore} from "./auth/store"
import 'tiny-ui/dist/styles/index.css';


// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';
import Products from "../routes/products";
import Menu from "../routes/Menu";
import Customers from '../routes/Customers';
import Users from "../routes/Users";
import Orders from "../routes/Orders";



const App = () => (
	<div id="app">
		<Header />
		<main>
			<Router>
				<Home path="/" />
				<Profile path="/profile/" user="me" />
				<Profile path="/profile/:user" />
				<Products path="/products"/>
				<Menu path="/menu"/>
				<Customers path="/customers"/>
				<Users path="/users"/>
				<Orders path="/orders" />
			</Router>
		</main>
	</div>
);

export default App;
