import { createClient } from '@supabase/supabase-js';
import { useAuthStore } from './store';
import { useEffect } from 'preact/hooks';
import {apiKey,auth,url} from "../../constants";


const supabase = createClient(
  'https://cgfnzzsiiaibebjibkds.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNnZm56enNpaWFpYmViamlia2RzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzM4MzI1MzcsImV4cCI6MTk4OTQwODUzN30.Hfuixtd83vJj81evFxUmE72AbUBC8-Bh58WljIVmmQI')


const useAuth = () => {
  const { currentUser, isAuthenticated, logout,logIn } = useAuthStore();



  useEffect(() => {
    let currentUser
    let consent = localStorage.getItem("hasConsented-v1")
    console.log("consent",consent)
      if(consent){
        let customerId = JSON.parse(localStorage.getItem("deviceData"))
        handleGet(customerId["id"]).then((customerExists)=>{
          if(customerExists !== null && !customerExists){
           handleCreateCustomer({name:'GUEST',device:customerId["id"]})
          }
        })
      }
    supabase.auth.getUser().then((current)=>{
      currentUser=JSON.stringify(current)
  if (current.data.user) {
      logIn(currentUser)
  }else{
    logout()
  }
})
}, [currentUser])


  const loginWithGoogle = async () => {
    try {
      const res = await supabase.auth.signInWithOAuth({ provider: 'google'});
      console.log("res",res)
      //setCurrentUser(res);
    } catch (error) {
      throw error;
    }
  }

  const logoutHook = async () => {
    try {
      console.log("sup")
      await supabase.auth.signOut()
        logout();
        window.location.reload()
    } catch (error) {
      throw error;
    }
  }

  const handleCreateCustomer = newCustomer => {
    fetch(url, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json',
    'apikey': apiKey,
    'Authorization': auth
    },
    body: JSON.stringify(newCustomer)
    })
    .then(res => res.json())
    .then(data => {
    })
    .catch(err => console.log(err));
    }

    const handleUpdate = (id, updatedCustomer) => {
      fetch(`${url}?device=eq.${id}&select=*`, {
      method: 'PATCH',
      headers: {
      'Content-Type': 'application/json',
      'apikey': apiKey,
      'Authorization': auth
      },
      body: JSON.stringify(updatedCustomer)
      })
      .then(res => res.json())
      .then(data => {
     // setCustomers(customers.map(c => c.id === id ? data : c));
      })
      .catch(err => console.log(err));
      }


    const handleGet = async  id => {
      return fetch(`${url}?device=eq.${id}&select=*`, {
      method: 'GET',
      headers: {
      'apikey': apiKey,
      'Authorization': auth
      }
      })
      .then(res => {
        if(res.status == 200){
          return res.json()
        }
        return null
      }).then(data=>{
          if(data.length > 0){
            return true
          }
          return false
      })
      .catch(err => {console.log(err); return null});
      }

  return { currentUser, isAuthenticated, loginWithGoogle, logoutHook };
};

export default useAuth;
