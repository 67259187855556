

const apiKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNnZm56enNpaWFpYmViamlia2RzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzM4MzI1MzcsImV4cCI6MTk4OTQwODUzN30.Hfuixtd83vJj81evFxUmE72AbUBC8-Bh58WljIVmmQI"
const auth = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNnZm56enNpaWFpYmViamlia2RzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzM4MzI1MzcsImV4cCI6MTk4OTQwODUzN30.Hfuixtd83vJj81evFxUmE72AbUBC8-Bh58WljIVmmQI'
const url = 'https://cgfnzzsiiaibebjibkds.supabase.co/rest/v1/customers';
const googleApiKey = "AIzaSyD0sDzsjmfKOoofZ5NdSCXikVID0uek1AM";

export{
    apiKey,
    auth,
    url,
    googleApiKey
}